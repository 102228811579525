import { Locale } from 'vant'
import { createI18n } from 'vue-i18n'

import zhCN from 'vant/lib/locale/lang/zh-CN'

import zh_CN from './zh_CN'
import store from '@/store';

export const i18n = createI18n({
  locale: 'zh_CN', // 设置默认语言
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  messages: {
    'zh_CN': {
      ...zh_CN,
      ...zhCN
    }
  }, // 设置资源文件对象
  $t(key) {
    console.log('this.locale.value', this.locale.value);
    return this.messages[this.locale.value][key];
  }
})

// 切换语言
export const setLang = (lang) => {
  // 设置组件国际化
  switch (lang) {
    case 'zh_CN':
      Locale.use('zh-CN', zhCN)
      break
    default:
      Locale.use('zh-CN', zhCN)
      break
  }
  // 当前语言
  store.commit('setCurrLang',lang);
  i18n.global.locale = lang;
}

// 获取当前语言
export const getLang = () => {
  return i18n && i18n.global.locale.value
}

// 获取UA语言类型
export const getUAgentLang = () => {
  let lang = store.getters.getCurrLang;
  if(lang){
    return lang;
  }else{
    const UA = window.navigator.userAgent
    const regx = new RegExp(`LANG/('zh-CN'})`, 'g')
    const result = regx.exec(UA)
    return result ? result[1] : 'zh-CN'
  }
}