<template>
  <router-view />
  <van-tabbar
      v-model="tabbaractive"
      v-if="isShowTabBar"
      fixed safe-area-inset-bottom>
    <van-tabbar-item name="home" replace to="/aboutus">关于我们</van-tabbar-item>
    <div class="nav-logo" @click="goback('/home')">
      <van-image :src="navlogo"></van-image>
    </div>
    <van-tabbar-item name="task" replace to="/ucenter">会员中心</van-tabbar-item>
  </van-tabbar>
</template>

<script>
// @ is an alias to /src
import {computed, onMounted, reactive, toRefs,ref, watch} from 'vue';
import {useStore} from "vuex";

export default {
  name: 'Index',
  setup() {
    const isShowTabBar = ref(true);
    const store = useStore();
    const tabbaractive = ref('home');
    const state = reactive({
      navlogo: require('@/assets/default/imgs/logo.png'),
    })
    const tabbar = computed({
      get: () => store.getters.getTabbar,
      set: val => {
        store.commit('setTabbar',val);
      }
    })
    watch(tabbar, () => {
      tabbaractive.value = tabbar.value;
    })
    onMounted(()=>{
    })
    return {
      ...toRefs(state),
      tabbaractive,
      isShowTabBar
    };
  },
}
</script>
