<template>
  <div id="home">
    <van-image :src="banner"></van-image>
    <div class="search">
      当前城市：
      <van-dropdown-menu><van-dropdown-item v-model="value" :options="option" @change="changecity"/></van-dropdown-menu>
      <div class="flex-search-right">
        <input type="text" v-model="searchinfo.name" :placeholder="placeholder"/>
        <van-button @click.stop="dosearch"></van-button>
      </div>
    </div>
    <div class="r-s">
      <h3>预约在线<span>BOOK ONLINE</span></h3>
      <div class="r-s-list">
        <van-list style="padding-top: 3px;" :immediate-check="false"
                  v-model:loading="loading"
                  :finished="finished"
                  finished-text="没有更多"
                  @load="getlist">
          <van-cell v-for="(item,index) in mlist" :key="item.id">
            <template #title>
              <van-image :src="item.pic ? item.pic : avatar"></van-image>
            </template>
            <template #default>
              <div class="title">{{ item.name }}<span>{{ item.title }}</span></div>
              <div class="instro">{{ item.description }}</div>
              <div class="bottom"><van-button @click="goback('/info',{mid:item.id})">立即预约</van-button></div>
            </template>
          </van-cell>
        </van-list>
      </div>
    </div>
    <div class="c-us">热线电话：18950135000</div>
  </div>
</template>

<script>
// @ is an alias to /src
import {onMounted, reactive, toRefs} from 'vue';
import {httpget} from "@/libs/api";

export default {
  name: 'Home',
  setup() {
    const state = reactive({
      banner: require('@/assets/default/imgs/banner.jpg'),
      avatar: require('@/assets/default/imgs/avatar.png'),
      searchinfo:{
        page: 0,
        name: '',
        city: '厦门市',
      },
      placeholder: '请输入按摩师姓名',
      mlist:[],
      option:[
      ],
      value:'厦门市',
      loading: false,
      finished:false,
    })
    const getlist = async () => {
      state.loading = true;
      if(!state.finished){
        state.searchinfo.page++;
      }
      let res = await httpget('/api/index/index', state.searchinfo);
      if(res.code===0){
        state.loading = false;
        if(state.searchinfo.page===1){
          state.mlist = [];
        }
        if(res.data.list.data.length>0){
          res.data.list.data.forEach((task)=>{
            state.mlist.push(task);
          })
        }
        if(res.data.list.data.length<20){
          state.finished = true;
        }
      }else{
        state.loading = false;
        state.finished = true;
        return ;
      }
    }
    const getcity = async () => {
      state.loading = true;
      let res = await httpget('/api/index/city');
      if(res.code===0){
        state.option = res.data;
        state.loading = false;
      }else{
        state.loading = false;
      }
    }
    const changecity = () => {
      state.searchinfo.city = state.value;
      state.searchinfo.page = 0;
      state.finished = false;
      getlist();
    }
    const dosearch = () => {
      state.searchinfo.page = 1;
      getlist();
    }
    onMounted(() => {
      state.page = 0;
      getlist();
      getcity();
    })
    return {
      ...toRefs(state),
      getlist,
      getcity,
      changecity,
      dosearch
    };
  },
}
</script>
