import axios from 'axios';

import { Toast } from 'vant';
import qs from 'qs';
import router  from "@/router/index";
import store from '@/store';

axios.defaults.baseURL=process.env.VUE_APP_API_ROOT;
axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
axios.interceptors.request.use(
    config => {
        if (window.location.pathname !== '/login') {
            config.headers['Authorization']= store.getters.getFrontToken + '=vueroute='+`${router.currentRoute.path}`;
        }
        if(config.url.indexOf('?')!=-1){
            config.url = config.url;
        }else{
            config.url = config.url;
        }
        return config;
    },
    err => {
        console.log('Promise.reject',err);
        return Promise.reject(err);
    });


export const getaxios = () =>{
    return axios;
}

const formatSuccessRes = (res) => {
    if(res.data.code<0){
        router.push({path: '/login'}).catch(err => {err});
        return {code: 1,msg: '请登陆',data: '',wait: '',time: ''};
    }
    return {
        code: res.data.code,
        msg: res.data.msg,
        data: res.data.data,
        wait: res.data.wait?res.data.wait:3,
        time: res.data.time?res.data.time:''
    };
};

const formatErrorRes = (res, returnmsg) => {
    if(returnmsg){
        Toast.fail('网络错误，请检查您的网络');
    }
    return {
        code: res.status?res.status:(res.code?res.code:1),
        msg: res.statusText?res.statusText:(res.message?res.message:''),
        data: {}
    };
};

export const httpget = (url, obj, isreturnmsg) => {
    return axios.get(url, {params: obj,timeout:6000})
        .then(res => {
            return formatSuccessRes(res);
        })
        .catch(res => {
            return formatErrorRes(res, isreturnmsg);
        });
};

export const httppost = (url, params, isreturnmsg) => {
    params = qs.stringify(params);
    return axios.post(url, params)
        .then(res => {
            return formatSuccessRes(res);
        })
        .catch(res => {
            return formatErrorRes(res, isreturnmsg);
        });
};

export const httppatch = (url, params, isreturnmsg) => {
    return axios.patch(url, params)
        .then(res => {
            return formatSuccessRes(res);
        })
        .catch(res => {
            return formatErrorRes(res, isreturnmsg);
        });
};

export const httpput = (url, params, isreturnmsg) => {
    return axios.put(url, params)
        .then(res => {
            return formatSuccessRes(res);
        })
        .catch(res => {
            return formatErrorRes(res, isreturnmsg);
        });
};

export const httpdelete = (url, params,isreturnmsg) => {
    return axios.delete(url, params)
        .then(res => {
            return formatSuccessRes(res);
        })
        .catch(res => {
            return formatErrorRes(res,isreturnmsg);
        });
};

export const httpfile = (url, params,headers,isreturnmsg) => {
    return axios.post(url, params,headers)
        .then(res => {
            return formatSuccessRes(res);
        })
        .catch(res => {
            return formatErrorRes(res,isreturnmsg);
        });
};
