<template>
    <router-view/>
</template>
<script>
import {onMounted} from "vue";
export default {
  name: 'App.vue',
  setup() {
    onMounted(()=>{
      window.onload = function() {
      }
    });
    return {
    }
  }
}
</script>