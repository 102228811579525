import { createStore } from 'vuex'

const defaultMemberInfo = {
  mem_id: 0,
  real_name: '',
  menutype: 0 // 菜单样式 0 横条 1 左边栏
};

let storage = window.localStorage;

export default createStore({
  state: {
    memberInfo: defaultMemberInfo, // 用户信息
    frontToken: '',
    memberSetting: {},
    currLang: 'th_TH', //语言包 默认cn 中文 en 英文
    langlist: [],
    inviteCode: '',
    currLine: 1,
    orderInfo: {},
    spTheme: 'purple', //主题
    homeTime: 0,
    tabbar: 'home',
  },
  getters: {
    // 获取用户信息
    getMemberInfo(state) {
      storage = storage ? storage : window.localStorage;
      try {
        let memberinfo = storage.getItem('memberInfo');
        let frontToken = storage.getItem('frontToken');
        if (memberinfo && frontToken) {
          state.memberInfo = JSON.parse(memberinfo);
          state.frontToken = frontToken;
        }
      } catch (e) {
        state.memberInfo = defaultMemberInfo;
      }
      return state.memberInfo;
    },
    getMemberSetting(state) {
      storage = storage ? storage : window.localStorage;
      try {
        let memberSetting = storage.getItem('memberSetting');
        if (memberSetting) {
          state.memberSetting = JSON.parse(memberSetting);
        }
      } catch (e) {
        state.memberSetting = {};
      }
      return state.memberSetting;
    },
    // 获取Token
    getFrontToken(state) {
      storage = storage ? storage : window.localStorage;
      try {
        let frontToken = storage.getItem('frontToken');
        if (frontToken != null) {
          state.frontToken = frontToken;
        }
      } catch (e) {
        state.frontToken = '';
      }
      return state.frontToken;
    },
    getCurrLang(state){
      storage = storage ? storage : window.localStorage;
      try {
        let currLang = storage.getItem('frontcurrLang');
        if (currLang != null) {
          state.currLang = currLang;
        }else{
          state.currLang = 'th_TH';
        }
      } catch (e) {
        state.currLang = 'th_TH';
      }
      return state.currLang;
    },
    getCurrLine(state){
      storage = storage ? storage : window.localStorage;
      try {
        let currLine = storage.getItem('frontcurrLine');
        if (currLine != null) {
          state.currLine = currLine;
        }else{
          state.currLine = 1;
        }
      } catch (e) {
        state.currLine = 1;
      }
      return state.currLine;
    },
    getSpTheme(state){
      storage = storage ? storage : window.localStorage;
      try {
        let spTheme = storage.getItem('spTheme');
        if (spTheme != null) {
          state.spTheme = spTheme;
        }else{
          state.spTheme = 'purple';
        }
      } catch (e) {
        state.spTheme = 'purple';
      }
      return state.spTheme;
    },

    getHomeTime(state){
      storage = storage ? storage : window.localStorage;
      try {
        let homeTime = storage.getItem('homeTime');
        if (homeTime != null) {
          state.homeTime = homeTime;
        }else{
          state.homeTime = 0;
        }
      } catch (e) {
        state.homeTime = 0;
      }
      return state.homeTime;
    },
    getTabbar(state){
      let sestorage = window.sessionStorage
      try {
        let tabbar = sestorage.getItem('tabbar');
        if (tabbar != null) {
          state.tabbar = tabbar;
        }else{
          state.tabbar = 'home';
        }
      } catch (e) {
        state.tabbar = 'home';
      }
      return state.tabbar;
    },
  },
  mutations: {
    // 存储用户信息
    setMemberInfo(state, memberInfo) {
      state.memberInfo = memberInfo;
      storage = storage ? storage : window.localStorage;
      storage.setItem("memberInfo", JSON.stringify(memberInfo));
    },
    setMemberSetting(state, memberSetting) {
      state.memberSetting = memberSetting;
      storage = storage ? storage : window.localStorage;
      storage.setItem("memberSetting", JSON.stringify(memberSetting));
    },
    // 初始化用户信息
    initMemberInfo(state) {
      state.memberInfo = defaultMemberInfo;
      storage = storage ? storage : window.localStorage;
      storage.setItem("memberInfo", JSON.stringify(defaultMemberInfo));
    },
    //存储Token
    setFrontToken(state, data) {
      state.frontToken = data;
      storage = storage ? storage : window.localStorage;
      storage.setItem("frontToken", data);
    },
    setCurrLang(state,data){
      state.currLang = data;
      let storage = window.localStorage;
      storage.setItem("frontcurrLang", data);
    },
    setLanglist(state, langlist) {
      state.langlist = langlist;
      storage = storage ? storage : window.localStorage;
      storage.setItem("frontlanglist", JSON.stringify(langlist));
    },
    setInviteCode(state, inviteCode) {
      state.inviteCode = inviteCode;
      storage = storage ? storage : window.localStorage;
      storage.setItem("inviteCode", inviteCode);
    },
    setCurrLine(state,data){
      state.currLine = data;
      let storage = window.localStorage;
      storage.setItem("frontcurrLine", data);
    },
    setSpTheme(state,data){
      state.spTheme = data;
      let storage = window.localStorage;
      storage.setItem("spTheme", data);
    },
    setHomeTime(state, data){
      state.homeTime = data;
      let storage = window.localStorage;
      storage.setItem('homeTime', data);
    },
    setTabbar(state, data){
      state.tabbar = data;
      let storage = window.sessionStorage;
      storage.setItem('tabbar', data);
    },
    // setOrderInfo(state, orderInfo) {
    //   state.orderInfo = orderInfo;
    //   storage = storage ? storage : window.localStorage;
    //   storage.setItem("orderInfo", JSON.stringify(orderInfo));
    // },
  },
  actions: {
    // 存储用户信息
    setMemberInfo(context, memberInfo) {
      context.commit('setMemberInfo', memberInfo);
    },
    setMemberSetting(context, memberSetting) {
      context.commit('setMemberSetting', memberSetting);
    },
    // 初始化用户信息
    initMemberInfo(context) {
      context.commit('initMemberInfo');
    },
    setFrontToken(context, data) {
      context.commit('setFrontToken', data);
    },
    setCurrLang(context,data){
      context.commit('setCurrLang', data);
    },
    setLanglist(context,data){
      context.commit('setLanglist', data);
    },
    setInviteCode(context,data){
      context.commit('setInviteCode', data);
    },
    setCurrLine(context,data){
      context.commit('setCurrLine', data);
    },
    setSpTheme(context,data){
      context.commit('setSpTheme', data);
    },
    setHomeTime(context, data){
      context.commit('setHomeTime', data);
    },
    setTabbar(context, data){
      context.commit('setTabbar', data);
    }
    // setOrderInfo(context, data) {
    //   context.commit('setOrderInfo', data);
    // },
  }
})
