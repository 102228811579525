import '@babel/polyfill';
import Es6Promise from 'es6-promise'
Es6Promise.polyfill();
import 'default-passive-events'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible/flexible'
import {Switch,AddressList,SubmitBar,PullRefresh,Progress,Card,SwipeCell,RadioGroup,Radio,Empty,Circle,Calendar,Stepper,Tag,List, Tab, Tabs,Grid, GridItem,NoticeBar,Swipe,SwipeItem,Form,NavBar,Image as VanImage,Field,Icon,Tabbar,Overlay, TabbarItem,Button,Checkbox,CheckboxGroup,Loading,DropdownMenu, DropdownItem,Picker,Popup,Toast,Col,Row,Cell, CellGroup,ActionSheet,Step, Steps, Lazyload,ImagePreview,Uploader,Dialog,CountDown,Popover,DatetimePicker} from 'vant';
import mixin from './libs/mixin';
import './assets/font/iconfont.css';
import VueCropper from 'vue-cropper';

import { i18n, setLang, getUAgentLang } from '@/lang'
function init () {
    const lang = getUAgentLang()
    setLang(lang)
}
init();
router.beforeEach((to,from,next)=>{
    if(to.query.invite){
        store.dispatch('setInviteCode',to.query.invite); // 邀请码
    }
    if(to.meta && to.meta.tabbar){
        store.dispatch('setTabbar',to.meta.tabbar);
    }
    next()
})
router.beforeResolve((to, from, next) => {
    if (to.name == "enterprise-detail" && from.name == "enterprise-approve") {
        this.$router.go();
    }
    next();
});
const theme = 'default';
require('vant/lib/index.less');
require('./assets/default/default.less');
document.title = '源古参生';
const app=createApp(App);
app.config.globalProperties.$theme = theme;
//路由和存储和多语言
app.use(store).use(router).use(i18n).mixin(mixin);
// vant按需调用
app.use(NavBar).use(VanImage)
    .use(Field).use(Icon)
    .use(Tabbar).use(TabbarItem)
    .use(Button).use(Checkbox).use(CheckboxGroup)
    .use(Loading).use(DropdownMenu).use(DropdownItem)
    .use(Picker).use(Popup).use(Toast).use(Form)
    .use(Swipe).use(SwipeItem).use(NoticeBar).use(Grid)
    .use(GridItem).use(Tab).use(Tabs).use(List)
    .use(Col).use(Row)
    .use(Cell).use(CellGroup)
    .use(ActionSheet)
    .use(Tag).use(SubmitBar)
    .use(ActionSheet)
    .use(Step).use(Steps).use(Lazyload).use(ImagePreview)
    .use(Uploader).use(Dialog).use(Stepper).use(Calendar).use(Circle)
    .use(Empty).use(RadioGroup).use(Radio).use(SwipeCell).use(Card)
    .use(Progress).use(PullRefresh).use(AddressList).use(Switch).use(CountDown).use(Popover)
    .use(Overlay).use(DatetimePicker)
    .use(VueCropper);
app.mount('#app');
