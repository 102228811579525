import {createRouter, createWebHistory} from 'vue-router'
import home from "../views/home.vue";
import index from "../views/index.vue";

const routes = [
    {
        path: '/',
        redirect: '/home',
        component: index,
        children: [
            {
                path: '/home',
                name: 'home',
                component: home,
                meta: {tabbar: 'home'}
            },
            {
                path: '/aboutus',
                name: 'aboutus',
                component: () => import('../views/aboutus.vue'),
                meta: {tabbar: 'home'},
            },
            {
                path: '/login',
                name: 'login',
                component: () => import('../views/login.vue'),
                meta: {tabbar: 'home'},
            },
            {
                path: '/register',
                name: 'register',
                component: () => import('../views/register.vue'),
                meta: {tabbar: 'home'},
            },
            {
                path: '/info',
                name: 'info',
                component: () => import('../views/info.vue'),
                meta: {tabbar: 'home'},
            },
            {
                path: '/ucenter',
                name: 'ucenter',
                component: () => import('../views/ucenter.vue'),
                meta: {tabbar: 'home'},
            },
            {
                path: '/profile',
                name: 'profile',
                component: () => import('../views/profile.vue'),
                meta: {tabbar: 'home'},
            },
            {
                path: '/order',
                name: 'order',
                component: () => import('../views/order.vue'),
                meta: {tabbar: 'home'},
            },
            {
                path: '/service',
                name: 'service',
                component: () => import('../views/service.vue'),
                meta: {tabbar: 'home'},
            },
            {
                path: '/modify',
                name: 'modify',
                component: () => import('../views/modify.vue'),
                meta: {tabbar: 'home'},
            },
            {
                path: '/fundlogs',
                name: 'fundlogs',
                component: () => import('../views/fundlogs.vue'),
                meta: {tabbar: 'home'},
            },
            {
                path: '/recharge',
                name: 'recharge',
                component: () => import('../views/recharge.vue'),
                meta: {tabbar: 'home'},
            },
            {
                path: '/wallet',
                name: 'wallet',
                component: () => import('../views/wallet.vue'),
                meta: {tabbar: 'home'},
            }
        ]
    },
    {
        path: '/oauth',
        name: 'oauth',
        component: () => import('../views/oauth.vue'),
        meta: {tabbar: 'home'},
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
